import { get, writable } from 'svelte/store';
import type { Writable } from 'svelte/store';

interface QueryStore<T> extends Writable<T> {
  setWithoutHistory?: (params: any) => void,
  keepHistory?: (param: any) => void,
  set_value?: (key: string, value: any) => void,
}

export function create_store<T>() {
  const query_store: QueryStore<T> = writable({} as T);
  const keepHistory = [];
  let disableHistory = false;

  query_store.setWithoutHistory = (params) => {
    disableHistory = true;
    query_store.set(params);
    disableHistory = false;
  }

  query_store.keepHistory = function(param) {
    keepHistory.push(param);
  }

  if (typeof window !== 'undefined') {
    let initial = {} as T;
    const handlePop = () => {

      const params = new URLSearchParams(window.location.search);
      params.forEach((value, param) => {
        try {
          value = JSON.parse(value);
        } catch {
          /* wasnt json. dont care */
        }
        initial[param] = value;
      });
      query_store.setWithoutHistory(initial);
    }

    handlePop();
    window.addEventListener('popstate', handlePop);

    const handler = (params) => {
      if (typeof history == 'undefined') return;
      let search = '';
      let pushHistory = false;

      for (const param in params) {
        let value = params[param as string];
        if (typeof value == 'undefined') continue;
        search += (search ? '&' : '?');
        if (!!value && typeof value == 'object') value = JSON.stringify(value);
        value = encodeURIComponent(value);
        search += param + '=' + value;
      }

      if (pushHistory) {
        history.pushState(history.state, document.title, window.location.pathname + search + window.location.hash);
      } else {
        history.replaceState(history.state, document.title, window.location.pathname + search + window.location.hash);
      }
    }

    query_store.subscribe(handler);

    query_store.set_value = (key: string, value: any) => {
      const existing = get(query_store);
      if (!value) {
        existing[key] = undefined;
      } else {
        existing[key] = value;
      }
      query_store.set(existing);
    }
  }

  return query_store
}