import { browser } from '$app/env';

export const debug = function() {
  if (browser) {
    const debug_flag_set = window.location.search.includes('debug=true');
    if (debug_flag_set) {
      return Function.prototype.bind.call(console.log, console);
    }
  }
  return () => { };
}();
